import React, { useEffect, useState } from 'react';
import { Card, CardBody, Container, Col, Row, Button, UncontrolledTooltip } from 'reactstrap';
import single from 'assets/img/common/single.png';
import share from 'assets/img/common/share.png'
import logo from 'assets/img/guestpage/LOGO_CHANNEL_SUMMIT_EMEA.png'
import Checkbox from '@mui/material/Checkbox';

import { commonService } from '_services/common.service';
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader";
import { useSelector, useDispatch } from 'react-redux';
import { history } from '_helpers';
import { ToastsStore, ToastsContainer } from 'react-toasts';
import { Spinner } from "views/Hoc/Spinner";
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
// import Guide from "assets/Exhibitor+Guide+CSE22v2.pdf";

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import ConfirmDialog from 'views/Hoc/Dialog/GlobalDialog';
import Moment from 'moment';

function scrollToDiv(sec_pac) {

  const element = document.getElementById(sec_pac);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}
function scrollToDiv1(reg_del) {
  const element = document.getElementById(reg_del);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}
function scrollToDiv2(save_delegate) {
  const element = document.getElementById(save_delegate);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}
const qty_options = [{ id: 1, name: "1", value: '1' }, { id: 2, name: "2", value: '2' },
  , { id: 3, name: "3", value: '3' }, { id: 4, name: "4", value: '4' }, { id: 5, name: "5", value: '5' }]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];


const ManageUser = ({ eventId }) => {

  const [registeredContact, setRegisteredContact] = useState({
    contact: null, package: null, payment: null, coordinatePack: null,delegates:false,eventChannelId:null
  });

  let [token, settoken] = useState(null);
  const [badgeAlert, setBadgeAlert] = useState(false);

  const [secRegAlert, setSecRegAlert] = useState(false);

  const [addDelegateAlert, setAddDelegateAlert] = useState(false);

  const [isDelegateFull, setDelegate] = useState(false);

  const [isSelectOpen, setSelectOpen] = useState(false);

  let [additionalBadge, setBadge] = useState(0);

  const [hideDelegateSave, setDelegateSave] = useState(true);

  let [loggedContact, setLoggedContact] = useState(null);
  let [packageId, setPackageId] = useState(null);

  const dispatch = useDispatch();

  const [selectedAddon, setSelectedAddon] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState([]);

  const [existingAddon, setExistingAddon] = useState([]);

  let [addonInfo, setAddonInfo] = useState([]);


  const userData = JSON.parse(localStorage.getItem('CHuser'));

  let event_redux = useSelector(state => state.event);
  let eventDetails = useSelector(state => state.event)?.eventDetails;
  let eventPackage = useSelector(state => state.event)?.eventPackage;

  let [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);
  const [paymentSplit, setPaymentSplit] = useState([]);
  let [registerType, setRegisterType] = useState(null);

  let defaultState = {
    channelId: userData?.channelId,
    eventId: eventDetails?.eventId,
    eventName: eventDetails?.event_name,
    cType: userData?.channelTypeId,
    pType: userData?.channelTypeId == '2' ? '1' : '2',
    eventType: eventDetails?.type,
    channelContacts: [],
    selectedContacts: [],
    eventChannel: [],
    existingParticipantIds: [],
    additionalMember: 0,
    marketingOptions: [],
    packageOptions: [],
    marketingOptionsPrice: 0,
    packageOptionsPrice: 0,
    addonPackages: [],
    eventPackages: [],
    coordinatesDetails:[],


    //for mail
    participatedContacts: [],

  };

  const [state, setState] = useState(defaultState);

  useEffect(() => {

    if (!eventDetails || !eventPackage) {
      history.push('/admin/events');
    }

    (async () => {
      await getContacts();
      await getPaymentSplit();
      await getEventChannelDetails();

    })();

  }, []);




  const getContacts = async () => {
    setLoading(true);
    await commonService.getServices('/channel/getEventChannelDetails/' + state.eventId + '/' + state.eventType).then(res => {
      state.channelContacts = res.data.contacts;
      state.selectedContacts = res.data.participants;
      state.eventChannel = res.data.eventChannel;

      state.existingParticipantIds = res.data.participants.map(x => +x.id);
      state.additionalMember = res.data.participants.filter(x =>  x.eventChannelId == registeredContact.eventChannelId).length; //res.data.participants?.length;

      

      //for sending email on changing participants
      state.participatedContacts = res.data.participants;

      loggedContact = state.selectedContacts.filter(x => x.id == userData?.contactId)[0];

      setLoggedContact({ ...loggedContact });
      setPackageId(loggedContact?.packageId);

      // state.channelContacts = state.channelContacts.map(x => {
      //   if (state.selectedContacts.filter(y => y.id == x.id).length) {

      //   }
      //   else return x;
      // });      
      // state.channelContacts = state.channelContacts.filter(x => x);
      //console.log('channelContacts', state.channelContacts);
      setState({ ...state });
    });
    setLoading(false);
  };

  const getPaymentSplit = async () => {
    setLoading(true);
    await commonService.getServices('/channel/getPaymentSplit/' + state.eventId).then(res => {
      // console.log("paymentSplit:",userData.channelTypeId,res.data);   
      if (res.data.length) {
        registerType = eventDetails.packages.find(x => x.packageId == res.data[0].packageId).isSeller == '1' ? 2 : userData.channelTypeId;
        setRegisterType(registerType);
      } else {
        registerType = userData.channelTypeId;
        setRegisterType(userData.channelTypeId);
      }

      setPaymentSplit(res.data);
    });
    setLoading(false);
  };


  // console.log('registered contact', registeredContact);

  const getEventChannelDetails = async () => {
    setLoading(true);
    await commonService.getServices('/channel/getSelectedEventDetails/' + state.eventId).then(res => {
      state.eventChannelDetails = res.data;

      if (res.data["result"].length) {
        res.data["result"].map(p => {
          console.log('1 changed here result :', p.registeredBy , '==', userData.contactId );
          if(p.registeredBy == userData.contactId) {
            console.log('1 changed here : ', (additionalBadge + parseInt(p.package?.no_of_access_badge)));
            //const secondarypack = state.eventChannelDetails.Packages?.find(addon => addon.packageId == +p.packageId);
            //if (secondarypack) {
            additionalBadge = (additionalBadge + parseInt(p.package?.no_of_access_badge));// parseInt(secondarypack.no_of_access_badge));
            setBadge(additionalBadge);
            //}   
          }
        })
      }

      //state.eventChannelDetails.result = res.data["result"].find(x => x.package["packageType"] == 1 && x.package["packageUser"] == 1);

      let coordinateResult = res.data["result"].find(x => x.registeredBy == state.eventChannelDetails.coordinatesdetails[0].contactId)

      state.eventChannelDetails.result = (coordinateResult)?coordinateResult:res?.data["result"].find(x => x.package["packageType"] == 1 && x.package["packageUser"] == 1);

      if(!state.eventChannelDetails.result){
        state.eventChannelDetails.result = res?.data["result"].find(x => x.package["packageType"] == 1 && x.package["packageUser"] == 1);
      }


      // if (state.eventChannelDetails.result['package']) {
      //   setBadge(+state.eventChannelDetails.result['package'].no_of_access_badge);
      // }
      if (state.eventChannelDetails?.result?.['additionalBadge']) {
        additionalBadge = additionalBadge + parseInt(state.eventChannelDetails?.result?.['additionalBadge']);
        setBadge(additionalBadge);
        console.log('2 changed here : ', additionalBadge);
      }

      registeredContact.eventChannelId = state.eventChannelDetails?.result?.id;
     
      if (state.eventChannelDetails.subscribedAddon.length) {
        console.log('subscribedAddon length: ', state.eventChannelDetails.subscribedAddon);
        state.eventChannelDetails.subscribedAddon.map(p => {
          if (state.eventChannelDetails.addonPackages?.filter(addon => addon.isBadgePack == 1 && addon.packageId == +p.packageId).length) {
            const badgepack = state.eventChannelDetails.addonPackages?.filter(addon => addon.isBadgePack == 1 && addon.packageId == +p.packageId);
            additionalBadge = additionalBadge + res.data.addonsFromBackend?.filter(addon => badgepack.map(p => +p.packageId).includes(+addon.packageId)).reduce((a, curr) => a + curr.quantity, 0);
            console.log('3 changed here : ', additionalBadge);
            setBadge(additionalBadge);
          }
        })
      }

      //console.log("additionalBadge:", additionalBadge);

      

      //registeredContact.contact = state.channelContacts.filter(x => x.id == state.eventChannelDetails.result.registeredBy)[0];

      registeredContact.contact = state.channelContacts.filter(x => x.id == state.eventChannelDetails.coordinatesdetails[0].contactId)[0];
      registeredContact.coordinatePack = state.eventChannelDetails.coordinatesdetails[0]?.Package;
      //console.log('registeredContact.coordinatePack === ', state.selectedContacts);

      registeredContact.delegates = (state.selectedContacts.filter(x => x.id ==  userData?.contactId && x.isDelegates == '1').length > 0)?true:false;
     // console.log('delegates ==', registeredContact.delegates, userData?.contactId)


      // state.channelContacts = state.channelContacts.filter(x => x.id!=state.eventChannelDetails.result.registeredBy);

      // state.channelContacts = state.channelContacts.filter(x => x);     
      console.log('data >>> ', state.selectedContacts)
      setSelectedDelegates(state.selectedContacts);
      

      //setSelectedDelegates(state.selectedContacts.filter(x => x.eventChannelId == registeredContact.eventChannelId));
      console.log('setSelectedDelegates >>> ', state.selectedContacts.filter(x => x.eventChannelId == registeredContact.eventChannelId));

      setPrimaryDelegates(state.channelContacts.filter(x => state.selectedContacts.map(sc => sc.id).includes(x.id)));

      console.log('state.selectedContacts ', state.selectedContacts)
      console.log('registeredContact.eventChannelId ', registeredContact.eventChannelId)
      if ((+additionalBadge) == (state.selectedContacts.filter(x => x.eventChannelId == registeredContact.eventChannelId)).length)
        setDelegate(true);

      // setSelectedDelegates(state.channelContacts.filter(x => state.selectedContacts.filter(c => c.id != state.eventChannelDetails.result.registeredBy).map(sc => sc.id).includes(x.id)));
      if (!registeredContact.contact)
        registeredContact.contact = state.selectedContacts.filter(x => x.id == state.eventChannelDetails.coordinatesdetails[0]);
      registeredContact.package = state.eventChannelDetails?.result?.package;
      registeredContact.payment = state.eventChannelDetails?.payment?.find(x => x.packageId == state.eventChannelDetails?.result?.packageId && x.eventId == state.eventChannelDetails?.result?.eventId && x.channelId == state.eventChannelDetails?.result?.channelId);

      state.addonPackages = state.eventChannelDetails.addonPackages?.filter(addon => addon.channelType == registerType && (addon.isPublic == 1 || state.eventChannelDetails.channelPackages?.map(id => +id.packageId).includes(addon.packageId)));

      let loggedUserRole = (userData.roleId == 1) ? 2 : 1;

      state.addonPackages = state.addonPackages.filter(addon => addon.packageUser != loggedUserRole);


      state.eventPackages = state.eventChannelDetails?.Packages?.filter(pack => (pack.isPublic == 1 || state.eventChannelDetails.channelPackages?.map(id => +id.packageId).includes(pack.packageId)));

      addonInfo = res.data.addonsFromBackend;

      setAddonInfo(addonInfo);
      // console.log("adds:",addonInfo);

      if (state.eventChannelDetails.subscribedAddon?.filter(p => p.packageId != null).length) {
        const selected = state.eventChannelDetails.addonPackages?.filter(addon => addon.channelType == registerType && state.eventChannelDetails.subscribedAddon.map(p => +p.packageId).includes(addon.packageId));

        const addons = [...existingAddon, ...selected];
        // console.log("adds:", addons);
        setExistingAddon(addons);
      }

      state.selectedContacts = state.selectedContacts.filter(x => x.id != state.eventChannelDetails?.result?.registeredBy);

      setRegisteredContact({ ...registeredContact });



      // if(state.eventChannelDetails.result?.registeredBy != userData?.contactId)
      //     state.selectedContacts = state.selectedContacts.filter(x => x.id != userData?.contactId);



      if (state.eventChannelDetails?.marketingOptions?.length && eventPackage.marketingOptions) {
        eventPackage.marketingOptions.map(x => {
          state.eventChannelDetails.marketingOptions.map(y => {
            if (y == x.optionId) {
              state.marketingOptions.push(x);
            }
          })
        });
      }

      if (state.eventChannelDetails?.packageOptions?.length && eventPackage.packageOptions) {
        eventPackage.packageOptions.map(x => {
          state.eventChannelDetails.packageOptions.map(y => {
            if (y == x.optionId) {
              state.packageOptions.push(x);
            }
          })
        });
      }

      setState({ ...state });
    })
    setLoading(false);
  }

  const joinEvent = async () => {
    setLoading(true);
    if (packageId) {

      if (!loggedContact?.packageId) {

        let data = {
          packageId: packageId,
          eventId: state.eventId,
        };

        await axios.post('/channel/joinEvent', data).then(res => ToastsStore.success('successfully registered for Event'));

        let eventData = {
          name: userData.firstName + ' ' + userData.lastName,
          email: userData.email,
          contactId: userData.contactId
        };


        axios.post('mailerservice/eventsRegisterMail', eventData);



      }
      history.push('/admin/events');



    } else {
      ToastsStore.error('Please Select Package');
    }
    setLoading(false);




  }

  //company name already taken

  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });

  const confirmPopup = (res) => {
    if (res.popUpId == 2) {
      cancelEvent();
    }
    setModalPopup(false);
  }
  const closePopup = () => {
    setModalPopup(false);
  };



  const packAlreadySet = () => {

    let normalmessage = <>

      <p>If you wish to change your package please contact <b><i>Liu</i></b> at <a href={"mailto:liu.viet@channelhub.net"}>liu.viet@channelhub.net</a>, Thanks.</p>
    </>;

    modalPopValue.message = normalmessage;
    modalPopValue.title = 'Package Already Selected';
    modalPopValue.popUpId = 1;
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);
  }

  const setPack = (id, value) => {

    const matched = state.addonPackages.filter(x => x.packageId == id && value == true);

    const removed = selectedAddon.filter(x => x.packageId == id && value == false).map(x => +x.packageId);

    const selected = [...selectedAddon.filter(x => !removed.includes(+x.packageId)), ...matched];

    // if (matched.filter(addon => addon.isBadgePack == '1').length) {
    //   setDelegate(false);
    //   setAddDelegateAlert(true);
    // }
    // else if (state.addonPackages.filter(x => x.packageId == id && x.isBadgePack == '1').length) {
    //   setSelectedDelegates(primaryDelegates);
    //   setDelegate(true);
    //   setDelegateSave(true);
    // }

    setSelectedAddon(selected);

    //console.log("selectedaddon:", selected, state.addonPackages);

    dispatch({ type: 'EVENT_PAYMENT', data: selected });

  }

  const setEventPackage = (id, value) => {

    const matched = state.eventPackages.filter(x => x.packageId == id && value == true);

    if (matched.length) {
      setDelegate(false);
      setAddDelegateAlert(true);

    }
    else {
      setSelectedDelegates(primaryDelegates);
      setDelegate(true);
      setDelegateSave(true);

    }

    setSelectedPackage(matched);



    // console.log("package:", selectedPackage);

  }

  const cancelEventPop = () => {

    let normalmessage = <>

      <p>Do you want to cancel the current event subscription ? </p>
    </>;

    modalPopValue.message = normalmessage;
    modalPopValue.title = 'Event Cancellation';
    modalPopValue.popUpId = 2;
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);
  }

  const cancelEvent = async () => {
    setLoading(true);
    await axios.post('/channel/cancelEvent', { eventId: state.eventId }).then(async res => {
      ToastsStore.success("Success");

      let mailData = {
        to: 'info@channelhub.net',
        contactId: userData.contactId,
        eventName: state.eventName,
        packageName: loggedContact.packageName
      }
     axios.post('mailerservice/eventCancelMail', mailData);

      await getContacts();
      await getEventChannelDetails();
    }).catch(err => {
      ToastsStore.error('Something went wrong');
      setLoading(false);
    })
    setLoading(false);

  }

  const payment = async (split) => {

    // console.log("split:",split,eventDetails,eventPackage)

    var userData = JSON.parse(localStorage.getItem('CHuser'));


    let pack = eventDetails.packages.find(p => p.packageId == split.packageId);//channel?.package;
    let packOffers = []; let vatPercentage; let vatAmount; let offerAmount = 0; let vatNumber; let isVatCompany;
    // console.log("pcka:", pack,split);


    //if (pack.offerIds && pack.offerIds != '') {
      let offers = (pack.offerIds)?pack.offerIds.split(','):[];
      await commonService.postService({ eventId: split.eventId, offers: offers, channelTypeId: userData.channelTypeId, regType: userData.eventRegisterAs == 1 ? 'Seller' : 'Buyer', channelId: userData.channelId, contactId: userData.contactId }, '/channelAdmin/packageOffers').then(res => {
        packOffers = res.data.offers;
        vatPercentage = res.data.hasVat;
        vatNumber = res.data.vatNumber;
        isVatCompany = res.data.isVatCompany;
        offerAmount = res.data.offers.filter(x => x.offerType == 1).length ? res.data.offers.filter(x => x.offerType == 1).reduce((a, curr) => parseInt(a) + parseInt(curr.couponOfferValue), 0) : 0;
      })
    //}

    // console.log("offerAmount:", packOffers, offerAmount, vatPercentage);
    const paymentDetails = {
      actualAmount: pack.price,
      package: [{ pacakgeId: pack.packageId, price: pack.price, packageName: pack.packageName }],
      channelId: userData.channelId,
      contactId: userData.contactId,
      // finalAmount: pack.price - offerAmount,
      finalAmount: split.splitAmount,//.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
      eventId: split.eventId,
      offerAmount: offerAmount,
      currCode: split.currCode,
      offers: packOffers,
      packageId: pack.packageId,
      vatPercentage: vatPercentage,
      isVatCompany: isVatCompany,
      vatNumber: vatNumber ? vatNumber : '',//vatPercentage?vatNumber:'',
      isPrimary: true,
      splitId: split.splitId,
      paymentMethod: split.paymentMethod,
      totalSplit: paymentSplit
    }

    localStorage.setItem('eventRedirect', JSON.stringify({ paymentDetails: paymentDetails }));
    dispatch({ type: 'EVENT_PAYMENT', data: paymentDetails });

    // dispatch({ type: 'EVENT', data: { ...eventDe } });

    history.push('/admin/Subscription/EventPayment');

  }
  // const downloadGuidePdf = () => {
  //   // Replace 'path_to_your_pdf.pdf' with the actual path to your PDF file
  //   const pdfPath = '/assets/EMEA_GUIDE';
  //   // Triggering the download
  //   fetch(pdfPath)
  //     .then(response => response.blob())
  //     .then(blob => {
  //       const url = window.URL.createObjectURL(new Blob([blob]));
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', 'EMEA_GUIDE.pdf'); // Change 'filename.pdf' to the desired name of the downloaded file
  //       document.body.appendChild(link);
  //       link.click();
  //       link.parentNode.removeChild(link);
  //     });
  //   };
  const saveDelegates = async () => {

    setLoading(true);
    let catalystData = [];

    let list = [];
    selectedDelegates.map(x => {
      let obj = {
        contactId: +x.id,
        channelType: userData.channelTypeId,
        channelId: userData.channelId,
        participantType: registerType == 2 ? 1 : 2,
        packageId: registeredContact.package.packageId,
        eventId: state.eventId,
        status: '1',
        profileurl: process.env.REACT_APP_LOCALSITE + 'login',
        isDelegates:'1',
        eventChannelId: registeredContact?.eventChannelId
      }


      list.push(obj);
    })

    let data = {
      list: list?.filter(x => !state.existingParticipantIds.includes(x.contactId)),
      additionalMembers: state.additionalMember,
    }

  if(data.list.length > 0) {
    await axios.post('/channel/addDelegates', data).then(res => {
      ToastsStore.success('successfully registered for Event');
      data.list.map(delegate => {
        catalystData.push({ contactId: +delegate.contactId, eventId: delegate.eventId })
        axios.post('mailerservice/eventsDelegateMail', delegate);
      })
    }).catch(err => {
      ToastsStore.error('Something went wrong!')
      setLoading(false);
    });
  } else {
    ToastsStore.error('Invalid User');
    setLoading(false);
  }

    //register to event catalyst
    // if (userData.channelTypeId == 2) {
    //   await axios.post('channelAdmin/boToEventCatalyst', catalystData).then(res => {
    //     eventCatalystRegister(userData, res.data);
    //     ToastsStore.success('Successfully registered to Event Catalyst');
    //   }).catch(err => ToastsStore.warning('something went wrong'));
    // }


    let eventData = {
      name: userData.firstName + ' ' + userData.lastName,
      email: userData.email,
      contactId: userData.contactId
    };


    // axios.post('mailerservice/eventsRegisterMail', eventData);


    setLoading(false);

    // history.push('/admin/events');
    history.push('/admin/events/eventManagement/' + state.eventId);


  }

  const removeDelegates = async (eventParticipantId) => {

    setLoading(true);
    
    let data = {
      eventParticipantId: eventParticipantId,
      eventChannelId: registeredContact?.eventChannelId,
      additionalMembers: parseInt(state.additionalMember) - parseInt(1),
      eventId: state.eventId,
    }

  
    await axios.post('/channel/deleteDelegates', data).then(res => {
      
      if(res.data.status){
      setSelectedDelegates(selectedDelegates.filter(x => x.eventParticipantId != eventParticipantId));
      ToastsStore.success('Successfully removed for Event');
      } else {
        ToastsStore.warning(res.data.message);
      }

      //console.log('deletedEvents : ', selectedDelegates)
      // data.list.map(delegate => {
      //   catalystData.push({ contactId: +delegate.contactId, eventId: delegate.eventId })
      //   axios.post('mailerservice/eventsDelegateMail', delegate);
      // })
    }).catch(err => {
      ToastsStore.error('Something went wrong!')
      setLoading(false);
    });
 

  //register to event catalyst
  // if (userData.channelTypeId == 2) {
  //   await axios.post('channelAdmin/boToEventCatalyst', catalystData).then(res => {
  //     eventCatalystRegister(userData, res.data);
  //     ToastsStore.success('Successfully registered to Event Catalyst');
  //   }).catch(err => ToastsStore.warning('something went wrong'));
  // }

   setLoading(false);
    history.push('/admin/events/eventManagement/' + state.eventId);
}


  const saveDelegatesWithNewPackage = async () => {

    //setLoading(true);
    let catalystData = [];

    let list = []; let channelEntry = {};

    setSelectedPackage((addon) => {
      return addon.map(x => {
        x["isSecondaryPackage"] = true;
        x["participants"] = selectedDelegates.filter(user => !primaryDelegates.map(p => p.id).includes(user.id)).map(x => +x.id);

        return x;
      })
    });

    dispatch({ type: 'EVENT_PAYMENT', data: selectedPackage });

    history.push('/admin/Subscription/EventPayment')

    /*if (selectedPackage.length == 1) {

      console.log("selectedPackage:", selectedPackage);

      let userData = JSON.parse(localStorage.getItem('CHuser'));

      let packageData = {
        price: parseFloat(selectedPackage[0].price),
        channelId: userData.channelId,
        contactId: selectedDelegates.filter(user => !primaryDelegates.map(p => p.id).includes(user.id)).map(x => +x.id)[0],
        participants: selectedDelegates.filter(user => !primaryDelegates.map(p => p.id).includes(user.id)).map(x => +x.id),
        eventId: selectedPackage[0].eventId,
        packageId: selectedPackage[0].packageId,
        package: selectedPackage
      }

      let eventData = {
        name: userData.firstName + ' ' + userData.lastName,
        email: userData.email,
        eventId: selectedPackage[0]?.eventId,
        contactId: packageData.contactId,
        templateId: selectedPackage[0].template,
        profileurl: process.env.REACT_APP_LOCALSITE + 'login'
      };


      await axios.post('/channel/addDelegatesWithPack', packageData).then(async (res) => {
        ToastsStore.success("Registered Successfully and Waiting for approval");

        setDelegateSave(true);
        await axios.post('mailerservice/eventsRegisterMail', eventData);

      }).catch(err => {
        ToastsStore.error('Something went wrong!')
        setLoading(false);
      });
    }*/
  }

  const eventCatalystRegister = async (userData, eventCatalystData) => {

    if (eventCatalystData.length) {
      await getPublicToken(userData.channelId);
      let req = {
        url: process.env.REACT_APP_EVENTCATALYST_API + 'oauth/token'
      }

      let catalystToken = await commonService.postService(req, 'account/eventcatalystToken').then(res => {
        if (res.data?.error) {
          ToastsStore.warning('Something went wrong');
        } else {
          return res.data;
        }
      }).catch(err => {
        console.log('err', err)
      })

      //sending first user to event catalyst
      const x = eventCatalystData[0];

      let pack = x.package.filter(p => p.contactId == x.contactId);

      let dec = x.detailDesc ? x.detailDesc : null
      //console.log('dec', dec);
      if ((dec === null) || (dec === '')) {
        dec = null;
      } else {
        dec = dec.toString();
        dec = dec.replace(/(<([^>]+)>)/ig, '').trim();
      }

      let data = {
        "participantId": parseInt(x.contactId),
        "firstName": x.firstName,
        "lastName": x.lastName,
        "email": x.email,
        "companyName": x.companyName,
        "companyId": parseInt(userData.channelId),
        //static need to replace in future
        "eventId": '053ac317-8db8-40c0-991e-bd52d861d6a0',
        "chEventId": state.eventId,
        "status": "Confirmed",
        "type": userData.channelTypeId === '2' ? "Vendor" : "Buyer",
        "typeId": parseInt(userData.channelTypeId),
        "timezone": "",
        "matchingScore": [],
        "country": x.isoCode,
        "profileUrl": process.env.REACT_APP_LOCALSITE + 'channelView/' + userData.channelId + '?token=' + token,
        "photoUrl": x.profileimage,
        "mobile": x.mobileNo,
        "jobTitle": x.jobTitle,
        "category": userData.channelTypeId === '2' ? 'Vendor' : userData.channelTypeId === '3' ? 'Distributor' : userData.channelTypeId === '4' ? 'Retailer' : 'Reseller',
        "logoUrl": x.companylogo,
        "existingCategory": x.ExistingCategory ? x.ExistingCategory.replace(/,/g, "|") : null,
        "interestedCategory": x.IntrestedCategory ? x.IntrestedCategory.replace(/,/g, "|") : null,
        "profileType": x.ProfileType ? x.ProfileType : null,
        "retailCustomerProfile": x.Retail_Customer_Profile ? x.Retail_Customer_Profile.replace(/,/g, "|").replace(/,/g, "|") : null,
        "detailDesc": dec,
        "password": x.password ? x.password : null,
        "package": pack.length > 0 ? pack[0] : {},
      }

      // // Status: Invited, Shortlist, Confirmed, Rejected, Cancelled, WaitingList, Declined
      let body = {
        token: catalystToken, //JSON.parse(localStorage.getItem('eventcatalyst_token')),
        url: process.env.REACT_APP_EVENTCATALYST_API + 'api/registration',
        body: data
      }


      await commonService.postService(body, 'account/postEventRegister').then(async res => {
        setLoading(false);
        await setEventContact(+x.contactId)
        await setEventParticipantStatus(state.eventId, +userData.channelId, +x.contactId);
      });
      //End sending first user to event catalyst


      if (eventCatalystData.length > 1) {
        setTimeout(async () => {
          eventCatalystData.slice(1).map(async x => {

            let pack = x.package.filter(p => p.contactId == x.contactId);

            let dec = x.detailDesc ? x.detailDesc : null
            //console.log('dec', dec);
            if ((dec === null) || (dec === '')) {
              dec = null;
            } else {
              dec = dec.toString();
              dec = dec.replace(/(<([^>]+)>)/ig, '').trim();
            }

            let data = {
              "participantId": parseInt(x.contactId),
              "firstName": x.firstName,
              "lastName": x.lastName,
              "email": x.email,
              "companyName": x.companyName,
              "companyId": parseInt(userData.channelId),
              //static need to replace in future
              "eventId": '053ac317-8db8-40c0-991e-bd52d861d6a0',
              "chEventId": state.eventId,
              "status": "Confirmed",
              "type": userData.channelTypeId === '2' ? "Vendor" : "Buyer",
              "typeId": parseInt(userData.channelTypeId),
              "timezone": "",
              "matchingScore": [],
              "country": x.isoCode,
              "profileUrl": process.env.REACT_APP_LOCALSITE + 'channelView/' + userData.channelId + '?token=' + token,
              "photoUrl": x.profileimage,
              "mobile": x.mobileNo,
              "jobTitle": x.jobTitle,
              "category": userData.channelTypeId === '2' ? 'Vendor' : userData.channelTypeId === '3' ? 'Distributor' : userData.channelTypeId === '4' ? 'Retailer' : 'Reseller',
              "logoUrl": x.companylogo,
              "existingCategory": x.ExistingCategory ? x.ExistingCategory.replace(/,/g, "|") : null,
              "interestedCategory": x.IntrestedCategory ? x.IntrestedCategory.replace(/,/g, "|") : null,
              "profileType": x.ProfileType ? x.ProfileType : null,
              "retailCustomerProfile": x.Retail_Customer_Profile ? x.Retail_Customer_Profile.replace(/,/g, "|").replace(/,/g, "|") : null,
              "detailDesc": dec,
              "password": x.password ? x.password : null,
              "package": pack.length > 0 ? pack[0] : {},
            }

            // // Status: Invited, Shortlist, Confirmed, Rejected, Cancelled, WaitingList, Declined
            let body = {
              token: catalystToken, //JSON.parse(localStorage.getItem('eventcatalyst_token')),
              url: process.env.REACT_APP_EVENTCATALYST_API + 'api/registration',
              body: data
            }

            await commonService.postService(body, 'account/postEventRegister').then(async res => {
              setLoading(false);
              await setEventContact(+x.contactId)
              await setEventParticipantStatus(eventId, +userData.channelId, +x.contactId);
            });

          })
        }, 5000);
      }
    }
  }

  const setQtyOption = (name, value) => {

    let quantity; let isExistingAddon = false; let existingQuantity; let existingAmount;

    if (existingAddon.filter(a => a.packageId == name).length > 0 || selectedAddon.filter(a => a.packageId == name).length > 0) {
      let addonDetail = addonInfo.find(addon => addon.packageId == name);
      if (addonDetail) {
        isExistingAddon = true;
        existingQuantity = +addonDetail.quantity;
        existingAmount = +addonDetail.finalAmount;
        quantity = value;
      } else {
        isExistingAddon = false;
        quantity = value;
      }

      // console.log("qty:",addonDetail,name,value,selectedAddon,state.addonPackages,quantity)

      setSelectedAddon((addon) => {
        return addon.map(x => {
          if (x.packageId == name) {
            x["qty"] = quantity;
            x["isExistingAddon"] = isExistingAddon;
            x["existingQuantity"] = existingQuantity;
            x["existingAmount"] = existingAmount;
          }
          return x;
        })
      });
    }

    // console.log("after qty:",selectedAddon);

    dispatch({ type: 'EVENT_PAYMENT', data: selectedAddon });
  }

  const setEventContact = async (contactId) => {
    await commonService.getServices('channelAdmin/setEventContact/' + contactId).then(res => {

    }, err => {
      console.log('err', err)
    });
  }

  const setEventParticipantStatus = async (eventId, channelId, contactId) => {
    let data = {
      eventId: eventId,
      channelId: channelId,
      contactId: contactId
    }
    await commonService.postService(data, 'channelAdmin/setEventParticipantStatus/').catch(err => ToastsStore.warning('something went wrong'));
  }

  const getPublicToken = async (channelId) => {

    let tokenData = await commonService.getServices('account/getPublicToken/' + channelId);
    if (tokenData) {

      token = tokenData.data.token;
      settoken(token);

    }
  }

  const addonPayClick = async () => {

    if (selectedAddon.length == 1 && parseFloat(selectedAddon[0].price) == 0) {
      //console.log("selectedaddon:", selectedAddon);

      let userData = JSON.parse(localStorage.getItem('CHuser'));

      let packageData = {
        actualAmount: 0,
        channelId: userData.channelId,
        contactId: userData.contactId,
        couponAmount: 0,
        couponId: null,
        eventId: selectedAddon[0].eventId,
        packageId: selectedAddon[0].packageId,
        finalAmount: 0,
        taxAmount: 0,
        totalDiscountedAmount: 0,
        subscriptionTypeId: 1,
        paymentIndent: null,

        // address: data.address,
        isPaid: '1',
        package: selectedAddon
      }

      const response = await commonService.postService(
        packageData
        , 'channel/subscription/eventSubscribe').then(res => {
          ToastsStore.success('Addon Successfully Added');
          window.location.reload();
          //history.push('/admin/events');
        }).catch(err => console.log('err', ToastsStore.error(err.response.data.message)));
    } else {
      history.push('/admin/Subscription/EventPayment')
    }

  }


  const [primaryDelegates, setPrimaryDelegates] = React.useState([]);
  const [selectedDelegates, setSelectedDelegates] = React.useState([]);

  const handleChange = (event) => {


    const {
      target: { value },
    } = event;
    console.log("value.length == ", value.length);
    console.log("additionalBadge == ", additionalBadge);
    // let addonBadge = 0;
    // if (selectedAddon.filter(addon => addon.isBadgePack == '1').length) {
    //   const badge = selectedAddon.find(addon => addon.isBadgePack == '1');
    //   if (badge.qty) {
    //     addonBadge = badge.qty;
    //   } else
    //     addonBadge = 1;
    // }

    if ((additionalBadge) < value.length) { //if ((+registeredContact?.package?.no_of_access_badge + additionalBadge) < value.length) {
      setBadgeAlert(true);
      setDelegate(true);
    }
    else {
      state.additionalMember = value.length;
      setState({ ...state });
      setSelectedDelegates(
        // On autofill we get a stringified value.
        // typeof value === 'string' ? value.split(',') : value,
        value
      );
      setDelegateSave(false);
    }
  };

  const downloadGuide = () => {
    // window.location = '/pdf/EMEA_GUIDE.pdf';
    document.getElementById('downloadClick').click();
  }
  const handlePackageAdd = (event) => {

    //let selectedPackages = state.eventChannelDetails.result.map(x => x.package);

    //let totalBadge = selectedPackages.reduce((a, curr) => a + curr.no_of_access_badge, 0);

    const {
      target: { value },
    } = event;
   // console.log("list:", additionalBadge, value, selectedPackage);


    let  selectedPackage_no_of_access_badge = (selectedPackage.length > 0)?selectedPackage[0].no_of_access_badge:0;
    if ((additionalBadge + parseInt(selectedPackage_no_of_access_badge)) < value.length) {
      setBadgeAlert(true);
      setDelegate(true);
    }
    else {
      state.additionalMember = value.length;
      setState({ ...state });
      setSelectedDelegates(
        // On autofill we get a stringified value.
        // typeof value === 'string' ? value.split(',') : value,
        value
      );
      if (value.length == 1)
        setDelegateSave(true);
      else
        setDelegateSave(false);
    }
  };

  const handleSelectOpen = () => {

    if (isDelegateFull) {
      setSelectOpen(false);
      setSecRegAlert(true);
    } else {
      setSelectOpen(!isSelectOpen);
    }

  }

  const handleSelectOpen1 = () => {

    if (isDelegateFull && hideDelegateSave) {
      setSelectOpen(false);
      setSecRegAlert(true);
    } else {
      setSelectOpen(!isSelectOpen);
    }

  }



  return (
    <div id='Events'>

      <ConfirmDialog
        open={(secRegAlert && !badgeAlert && registerType != 2) || (secRegAlert && registerType == 2 && !isSelectOpen && hideDelegateSave)}
        value={{
          title: 'Event Registration',
          message: registerType == 2 ? 'Maximum limit of participants reached, Please Purchase addon to add more delegates' : 'Maximum limit of participants reached, Please Select Secondary Package to add more delegates',
          second_message: ''
        }}
        handleSave={() => {
          setSecRegAlert(false);
          if (registerType == 2)
            scrollToDiv('addon_pac')
          else
            scrollToDiv('sec_pac')
        }}
        handleClose={() => setSecRegAlert(false)}
      />

      <ConfirmDialog
        open={badgeAlert && !secRegAlert}
        value={{
          title: 'Event Registration',
          message: registerType == 2 ? 'Maximum limit of participants reached, Click Save to add delegates' : 'Maximum limit of participants reached',
          second_message: ''
        }}
        handleSave={() => {
          setBadgeAlert(false);
          if (registerType == 2)
            scrollToDiv2('addon_save_delegate')
          else
            scrollToDiv2('save_delegate')
        }}


        handleClose={() => setBadgeAlert(false)}
      />
      <ConfirmDialog open={addDelegateAlert} value={{ title: 'Event Registration', message: 'Please Select delegates in above section and Click Save', second_message: '' }} handleSave={() => {
        setAddDelegateAlert(false);
        if (registerType == 2)
          scrollToDiv('addon_reg_del')
        else
          scrollToDiv1('reg_del')
      }} handleClose={() => setAddDelegateAlert(false)} />


      {/* <SimpleHeader location={props.match}/>
            <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
            {loading ? <Spinner /> : <>
            <div className='text-center'>
            <img src={logo} width={180}/>
            </div>
            <h1 className='text-center mt-3 mb-4'>Channel Summit Registration Details</h1>

            </>
            } */}
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
      {loading ? <Spinner /> : <>
        <Card>
          <Row>
            {(registerType == 2) ?
              <Col lg='2'>
                <div className='exhibitor-guide'>
                  <img src={"/images/Exhibitor_guide_ image.png"} title='Exhibitor Guide' style={{ cursor: 'pointer' }} alt='Exhibitor Guide' onClick={downloadGuide} />
                </div>
                <a className='down-exhibitor' download="EMEA_GUIDE.pdf" href={"/pdf/EMEA_GUIDE.pdf"} id="downloadClick" title='Download Exhibitor Guide pdf' > <i class="fa fa-download" aria-hidden="true" ></i> Download </a>
              </Col> : ''} 
            <Col lg='10'>
              <div fluid={registeredContact?.contact?.id != userData.contactId}>
                <Card>
                  <CardBody>

                    {/* {userData?.channelTypeId==2 && <a className='btn btn-primary' href={process.env.REACT_APP_CDN_URL+"document/company/pdf/Exhibitor%2BGuide%2BCSE22v2.pdf"} target="_blank" >Exhibitors Guide</a>} */}


                    {!!registeredContact.contact && <>
                      <div>
                        <h4 className='text-center'>Event Coordinator</h4>
                      </div>
                      <Col lg='12' className='event-card p-3 mb-4'>
                        <Row>
                          <Col lg='6' className='pt-3'>
                            <div className='text-center'>
                              <a class="avatar rounded-circle" href="#pablo"><img src={registeredContact.contact?.documentPath ? process.env.REACT_APP_CDN_URL + registeredContact.contact?.documentPath : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} width={100} /></a>
                              <h4>
                                {registeredContact.contact?.name}
                              </h4>
                              {/* <a className='btn btn-primary' href={"/pdf/EMEA_GUIDE.pdf"} target="_blank" >Exhibitors Guide</a> */}
                              {/* <a className='btn btn-primary' download="EMEA_GUIDE.pdf" href={"/pdf/EMEA_GUIDE.pdf"} style={{ marginTop: '7px' }}  ><i class="fa fa-download" aria-hidden="true" ></i> Download</a> */}
                              {/* <button class="btn btn-primary btn-sm p-2 m-3" type="button" onClick={() => downloadGuidePdf()}><i class="fa fa-download" aria-hidden="true"></i> Download</button> */}
                            </div>
                          </Col>
                          {!!registeredContact.package &&
                            <Col lg='6'>
                               {(registeredContact.delegates == true) && <><p>Delegates User</p></>}
                              <h4>Selected Package</h4>
                              <p className='mb-2'>Package Name : {registeredContact.package.packageName}</p>
                              Description : <p class=" text-left" dangerouslySetInnerHTML={{ __html: registeredContact.package.description }} />

                              {registerType == 2 && <>
                                <p className='mb-2'>Maximum No of Delegates : {additionalBadge}</p>
                                <p className='mb-2'>No of Registered Delegates : {selectedDelegates.length}</p> </>}

                              <p className='mb-2'>Price : {parseFloat(registeredContact.payment?.actualAmount) ? (parseFloat(registeredContact.payment?.actualAmount) - (parseFloat(registeredContact.payment?.totalDiscountedAmount))).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',') : registeredContact.package.price.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')} {(paymentSplit.length > 0 && paymentSplit[0].currCode == "USD") ? '$' : '€'}

                                {registeredContact.contact?.id == userData?.contactId && <><i id={'Ueditreg'} onClick={() => packAlreadySet()} style={{ padding: "10px 10px 0 10px" }} class="fas fa-edit pointer"></i>
                                  <UncontrolledTooltip target={'Ueditreg'} placement='bottom'> Edit Pack </UncontrolledTooltip></>}

                              </p>
                            </Col>
                          }
                        </Row>
                      </Col>
                    </>
                    }


                    {(paymentSplit.length > 0 && paymentSplit[0].paymentMethod == 2) && <>
                      <div>
                        <h4 className='text-center'>Payment Split</h4>
                      </div>

                      {paymentSplit?.map(split =>
                        <Col lg='12' className='event-card p-3 mb-4'>
                          <Row>
                            <Col lg='6' className='pt-3'>

                              <div className='text-left'>
                                <h4>{"Installment: " + split.splitId}</h4>
                                <h4>{"Payment Date: " + Moment(split.paymentDate).format('DD-MM-YYYY')}</h4>
                                <h4>{"Due Amount: " + split.splitAmount.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')} {split?.currCode == 'USD' ? '$' : '€'}</h4>



                              </div>
                            </Col>

                            <Col lg='6' className='pt-3'>
                              <Button className="mt-1 mb-2 btn-apply" onClick={() => payment(split)} disabled={split.paymentStatus == 1 ? true : false} variant="outlined" size="m" color={split.paymentStatus == 1 ? "success" : "primary"} style={{ float: 'right' }}>
                                {split.paymentStatus == 1 ? "Paid" : "Pay Now"}
                              </Button>
                            </Col>

                          </Row>
                        </Col>)}
                    </>
                    }

                    {registerType == 2 && <div>
                      <h4 className='text-center'>Registered Delegates</h4>
                      <Col lg='12' className='event-card p-3 mb-4'>
                        <Row id="addon_reg_del">
                          <Col lg='12' className='pt-3'>
                            <FormControl sx={{ m: 1, }} style={{ width: '100%' }}>
                              <InputLabel className='whiteback' id="demo-multiple-checkbox-label" style={{ backgroundColor: '#fff' }}>Add more delegates</InputLabel>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                label="Add more delegates"
                                value={selectedDelegates}
                                onChange={handleChange}
                                open={isSelectOpen}
                                onClick={handleSelectOpen1}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => !isDelegateFull ? selected.map(x => x.name).join(',') : ''}
                                MenuProps={MenuProps}
                              >
                                {state.channelContacts.map((x) => (
                                  <MenuItem key={x.id} value={x} disabled={isDelegateFull ? true : false}>

                                    <Checkbox checked={selectedDelegates.filter(p => p.id == x.id).length > 0} />
                                    <img style={{ width: '24px', height: '24px', objectFit: 'cover', marginRight: '10px' }}
                                      alt="..."
                                      src={x?.documentPath ? process.env.REACT_APP_CDN_URL + x?.documentPath : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
                                    />
                                    <ListItemText primary={x.name} />

                                  </MenuItem>
                                ))}

                                <MenuItem key='NewUser' value='NewUser' disabled={isDelegateFull ? true : false}>
                                  <a style={{ borderBottom: "1px solid #2c9db7", marginLeft: '15px', color: '#2c9db7' }} onClick={() => { history.push({ pathname: '/admin/userManage/userform', state: { eventId: state.eventId, forEvent: true, type: 3, addOrEdit: 'ADD' } }) }} className="mt-1 mb-2 btn-apply" variant="outlined" size="m" color="primary">
                                    + Add User
                                  </a>
                                </MenuItem>
                              </Select>
                            </FormControl>
                            {isDelegateFull && <span>If you wish to modify your delegate list for the Channel Summit event, please urgently contact  liu.viet@channelhub.net</span>}

                          </Col>
                          <Col lg='12' className='pt-3' id="addon_save_delegate">
                            <Button disabled={hideDelegateSave ? true : false} onClick={() => saveDelegates()} className="mt-1 mb-2 btn-apply" variant="outlined" size="m" color="primary" style={{ float: 'right' }}>
                              Save
                            </Button>
                          </Col>

                          <div style={{ float: "left", width: '100%' }}>
                            <Row>
                              {selectedDelegates.map((x) => (
                                <Col lg='2' md="4" col-sm-6 className='pt-3'>
                                  <div className='text-center'>
                                    <h4 style={{ float: 'right' }}>
                                      {x?.isDelegates == '1' ? <i class="fa fa-times" style={{ color: 'green' }} onClick={() => removeDelegates(x.eventParticipantId)} ></i> : <></>}
                                    </h4>
                                    <a class="avatar rounded-circle" href="#pablo"><img src={x?.documentPath ? process.env.REACT_APP_CDN_URL + x?.documentPath : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} width={100} /></a>
                                    <h4>
                                      {x?.name} {x?.status == '1' ? <i class="fa fa-check" style={{ color: 'green' }}></i> : <></>}
                                    </h4>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </div>


                          {/* <Col lg='12' className='pt-3'>   
                        {registeredContact?.package?.no_of_access_badge==state.additionalMember &&
                        <p className='mb-2' style={{color:'red'}}>Maximum limit of participants reached</p>}
                        </Col> */}
                        </Row>
                      </Col>
                    </div>}

                    {(registerType != 2) && <div>

                      <h4 className='text-center'>Registered Delegates</h4>
                      <Col lg='12' className='event-card p-3 mb-4'>


                        <Row id="reg_del">
                          <Col lg='12' className='pt-3'>
                            <FormControl sx={{ m: 1, }} style={{ width: '100%' }}>
                              <InputLabel className='whiteback' id="demo-multiple-checkbox-label" style={{ backgroundColor: '#fff' }}>Add more delegates</InputLabel>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                label="Add more delegates"
                                value={selectedDelegates}
                                open={isSelectOpen}
                                onClick={handleSelectOpen}
                                //onClose={() => setSecRegAlert(isDelegateFull ? true : false)}
                                onChange={handlePackageAdd}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => !isDelegateFull ? selected.map(x => x.name).join(',') : ''}
                                MenuProps={MenuProps}
                              >
                                {state.channelContacts.map((x) => (
                                  <MenuItem key={x.id} value={x} disabled={(isDelegateFull || primaryDelegates.filter(p => p.id == x.id).length > 0) ? true : false}>

                                    <Checkbox checked={selectedDelegates.filter(p => p.id == x.id).length > 0}

                                    />
                                    <img style={{ width: '24px', height: '24px', objectFit: 'cover', marginRight: '10px' }}
                                      alt="..."
                                      src={x?.documentPath ? process.env.REACT_APP_CDN_URL + x?.documentPath : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
                                    />
                                    <ListItemText primary={x.name} />

                                  </MenuItem>
                                ))}

                                <MenuItem key='NewUser' value='NewUser' disabled={isDelegateFull ? true : false}>
                                  <a style={{ borderBottom: "1px solid #2c9db7", marginLeft: '15px', color: '#2c9db7' }} onClick={() => { history.push({ pathname: '/admin/userManage/userform', state: { eventId: state.eventId, forEvent: true, type: 3, addOrEdit: 'ADD' } }) }} className="mt-1 mb-2 btn-apply" variant="outlined" size="m" color="primary">
                                    + Add User
                                  </a>
                                </MenuItem>
                              </Select>
                            </FormControl>
                            {isDelegateFull && <span>If you wish to modify your delegate list for the Channel Summit event, please urgently contact  liu.viet@channelhub.net</span>}

                          </Col>

                          <Col id="sec_pac">
                            {(isDelegateFull || state.eventPackages?.length != 0) &&
                              <>
                                <h4 className='mb-4 mt-4'>Select Secondary Package</h4>
                                <Row>
                                  {state.eventPackages?.map(x => {

                                    let currCode = paymentSplit.length ? paymentSplit[0].currCode : 'EUR';
                                    // let addonDetail = addonInfo.find(addon => addon.packageId == x.packageId);
                                    // console.log("addondetails:",addonDetail);
                                    return (
                                      <Col lg='4'>

                                        <div className='p-3' style={{ marginBottom: "20px", cursor: "pointer", minHeight: '250px', border: '1px solid #2c9db7', borderRadius: '5px', ...packageId == 204 && { boxShadow: "0px 1px 20px #2c9db7" } }}>

                                          <div>
                                            <Checkbox
                                              checked={(selectedPackage.filter(a => a.packageId == x.packageId).length > 0) ? true : false}
                                              //disabled={selectedPackage.filter(a => a.packageId == x.packageId).length == 0 ? false : selectedPackage.filter(a => a.packageId == x.packageId).length > 0 ? false : true}
                                              color="success"
                                              onChange={(e) => { e.preventDefault(); setEventPackage(x.packageId, e.target.checked) }}
                                              inputProps={{ 'aria-label': 'controlled' }}
                                              style={{ float: "right" }}
                                            />
                                            <div>
                                              <h3 style={{ fontSize: '25px', fontWeight: 'bold' }}>{`${x.packageName}  `}
                                              </h3>

                                            </div>
                                            <div>
                                              <span className='mr-2 display-1 font-weight-light'> {selectedAddon?.find(sa => sa.packageId == x.packageId)?.qty ? parseFloat(selectedAddon.find(sa => sa.packageId == x.packageId).qty * +x.price).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',') : x.price.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')}</span> {currCode == 'USD' ? <i class="fas fa-dollar-sign"></i> : <i class="fas fa-euro-sign"></i>}</div>
                                          </div>

                                          {/* <div className="col-lg-2">                                       

                                        <InputLabel id="demo-select-small" style={{ position: 'absolute', zIndex: 1, backgroundColor: '#fff', fontSize: '10px', top: '-5px', padding: '3px 10px', left: '10px' }}>Qty</InputLabel>
                                        <Select
                                          label="Qty"
                                          id="qty" //{period.id.toString()}
                                          name={x.packageId}
                                          disabled={selectedAddon.filter(a => a.packageId == x.packageId).length > 0 ? false : true}
                                          value={selectedAddon?.find(sa => sa.packageId == x.packageId)?.qty ? selectedAddon?.find(sa => sa.packageId == x.packageId)?.qty : 1}
                                          size="small"
                                          onChange={(e) => setQtyOption(e.target.name, +e.target.value)}
                                        >
                                          {
                                            qty_options.map(x =>
                                              <MenuItem value={x.value}>{x.name}</MenuItem>
                                            )}
                                        </Select>


                                      </div> */}


                                          <div className='d-flex justify-content-between'>
                                            <div>

                                              <p class=" text-left text-primary" dangerouslySetInnerHTML={{ __html: x.description }} />

                                            </div>

                                          </div>

                                          <p className='mb-2'>Maximum No of Delegates : {+x.no_of_access_badge}</p>

                                        </div>
                                      </Col>)
                                  })}

                                </Row>
                                <div className='text-right w-75'>
                                  {registeredContact?.contact?.id != userData.contactId && !loggedContact?.packageId && <Button onClick={() => joinEvent()} color='primary' className='text-uppercase'>Proceed</Button>}
                                </div>
                              </>
                            }
                          </Col>

                          <Col lg='12' className='pt-3' id="save_delegate">
                            <Button disabled={hideDelegateSave} onClick={() => saveDelegatesWithNewPackage()} className="mt-1 mb-2 btn-apply" variant="outlined" size="m" color="primary" style={{ float: 'right' }}>
                              Save
                            </Button>
                          </Col>

                          {/* <Col lg='12' className='pt-3'>   
                        {registeredContact?.package?.no_of_access_badge==state.additionalMember &&
                        <p className='mb-2' style={{color:'red'}}>Maximum limit of participants reached</p>}
                        </Col> */}
                        </Row>
                      </Col>
                    </div>}

                    {(!!state.selectedContacts.length && userData.channelTypeId != 2) && <div>
                      <h4 className='text-center'>Additional Delegates</h4>

                      <Col lg='12' className='event-card p-3 mb-4'>
                        <Row>
                          {
                            state.selectedContacts.map(x =>
                            (x.id != registeredContact?.contact?.id &&
                              <Col lg='4' className='pt-3'>
                                    <h4 style={{ float: 'right' }}>
                                      {x?.isDelegates == '1' ? <i class="fa fa-times" style={{ color: 'green' }} onClick={() => removeDelegates(x.eventParticipantId)} ></i> : <></>}
                                    </h4>
                                <div className='text-center'>
                                  <a class="avatar rounded-circle" href="#pablo"><img src={x?.documentPath ? process.env.REACT_APP_CDN_URL + x?.documentPath : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} width={100} /></a>
                                  {state.eventChannelDetails?.payment?.filter(pay => pay.contactId == x.id && (pay.isPaid == null || pay.isPaid == '0')).length > 0 ?
                                    <h4>
                                      {x?.name} {x?.status == '1' ? <p style={{ color: 'gray' }}>Payment Pending</p> : <></>}
                                    </h4>
                                    :
                                    <h4>
                                      {x?.name} {x?.status == '1' ? <i class="fa fa-check" style={{ color: 'green' }}></i> : <></>}
                                    </h4>}
                                </div>
                              </Col>
                            ))
                          }
                          {/* <Col lg='4'></Col>
                              <Col lg='4'>
                                {(userData.channelTypeId != 2 && state.eventType == 1) && <><h4>Selected Package</h4>
                                  <p className='mb-2'>Package Name : {x.packageName}</p>
                                  Description : <p class=" text-left" dangerouslySetInnerHTML={{ __html: x.description }} />
                                  <p className='mb-2'>Price : {x.price}
                                    {x.id == userData?.contactId && <><i id={'Uedit' + x.id} onClick={() => packAlreadySet()} style={{ padding: "10px 10px 0 10px" }} class="fas fa-edit pointer"></i>
                                      <UncontrolledTooltip target={'Uedit' + x.id} placement='bottom'> Edit Pack </UncontrolledTooltip></>}
                                  </p></>}

                              </Col> */}
                        </Row>
                      </Col>
                    </div>}



                    {existingAddon?.length > 0 && <>
                      <Col lg='12' className='event-card  mb-4' style={{ padding: "30px" }}>
                        <h4 className='mb-4'>Already selected item</h4>

                        {existingAddon.map(x => {

                          let currCode = paymentSplit.length ? paymentSplit[0].currCode : 'EUR';

                          let packageName = state.addonPackages.find(addon => addon.packageId == x.packageId)?.packageName;
                          let addonDetail = addonInfo.find(addon => addon.packageId == x.packageId);

                          return (
                            // <ul>
                            // <li>
                            parseFloat(addonDetail?.finalAmount) > 0 ?
                              <h4 style={{ fontSize: '15px', fontWeight: 'bold' }}> - {`${packageName} ${addonDetail?.quantity ? `(×${addonDetail?.quantity})` : ``} : ${addonDetail?.finalAmount.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')} ${currCode == 'USD' ? '$' : '€'} ${+addonDetail?.discount > 0 ? `(after ${addonDetail?.discount.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')} ${currCode == 'USD' ? '$' : '€'} discount) ` : ``}  `}
                              </h4> : <h4 style={{ fontSize: '15px', fontWeight: 'bold' }}> - {`${packageName} ${addonDetail?.quantity ? `(×${addonDetail?.quantity})` : ``} `}
                              </h4>
                            // </li>
                            // </ul>

                          )
                        })}
                      </Col>

                    </>}


                    {state.addonPackages.length > 0 && <>
                      <Col lg='12' className='event-card p-3 mb-4' style={{ minHeight: '350px' }} id="addon_pac">
                        <h4 className='mb-4'>Select Your Addon</h4>
                        <Row>
                          {state.addonPackages.map(x => {

                            let currCode = paymentSplit.length ? paymentSplit[0].currCode : 'EUR';
                            // let addonDetail = addonInfo.find(addon => addon.packageId == x.packageId);
                            // console.log("addondetails:",addonDetail);
                            return (
                              <Col lg='4'>

                                <div className='p-3' style={{ marginBottom: "20px", cursor: "pointer", minHeight: '250px', border: '1px solid #2c9db7', borderRadius: '5px', ...packageId == 204 && { boxShadow: "0px 1px 20px #2c9db7" } }}>

                                  <div>
                                    <Checkbox
                                      // checked={(existingAddon.filter(a => a.packageId == x.packageId).length > 0 || selectedAddon.filter(a => a.packageId == x.packageId).length > 0) ? true : false}
                                      // disabled={existingAddon.filter(a => a.packageId == x.packageId).length > 0 ? true : false}
                                      color="success"
                                      onChange={(e) => { e.preventDefault(); setPack(x.packageId, e.target.checked) }}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                      style={{ float: "right" }}
                                    />
                                    <div>
                                      <h3 style={{ fontSize: '25px', fontWeight: 'bold' }}>{`${x.packageName}  `}
                                      </h3>

                                    </div>
                                    <div>
                                      <span className='mr-2 display-1 font-weight-light'> {selectedAddon?.find(sa => sa.packageId == x.packageId)?.qty ? parseFloat(selectedAddon.find(sa => sa.packageId == x.packageId).qty * +x.price).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',') : x.price.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')}</span> {currCode == 'USD' ? <i class="fas fa-dollar-sign"></i> : <i class="fas fa-euro-sign"></i>}</div>
                                  </div>

                                  <div className="col-lg-2">
                                    {/* <Button style={{ margin: 'auto' }} color="primary" type="button" onClick={() => handleAddonDiscount(addon.id)}>Apply</Button> */}

                                    <InputLabel id="demo-select-small" style={{ position: 'absolute', zIndex: 1, backgroundColor: '#fff', fontSize: '10px', top: '-5px', padding: '3px 10px', left: '10px' }}>Qty</InputLabel>
                                    <Select
                                      label="Qty"
                                      id="qty" //{period.id.toString()}
                                      name={x.packageId}
                                      disabled={selectedAddon.filter(a => a.packageId == x.packageId).length > 0 ? false : true}
                                      value={selectedAddon?.find(sa => sa.packageId == x.packageId)?.qty ? selectedAddon?.find(sa => sa.packageId == x.packageId)?.qty : 1}
                                      size="small"
                                      onChange={(e) => setQtyOption(e.target.name, +e.target.value)}
                                    >
                                      {
                                        qty_options.map(x =>
                                          <MenuItem value={x.value}>{x.name}</MenuItem>
                                        )}
                                    </Select>


                                  </div>


                                  <div className='d-flex justify-content-between'>
                                    <div>

                                      <p class=" text-left text-primary" dangerouslySetInnerHTML={{ __html: x.description }} />

                                    </div>

                                  </div>
                                </div>
                              </Col>)
                          })}

                        </Row>
                        <div className='text-right w-75'>
                          {registeredContact?.contact?.id != userData.contactId && !loggedContact?.packageId && <Button onClick={() => joinEvent()} color='primary' className='text-uppercase'>Proceed</Button>}
                        </div>
                      </Col>




                      {/* { loggedContact?.packageId && <Button className="btn btn-warning" onClick={!loggedContact?.isCancelled ? cancelEventPop : ''}>
                            {!loggedContact?.isCancelled ? 'Cancel Event' : 'Event Cancellation Pending'}
                        </Button>} */}


                      <Button disabled={selectedAddon.length > 0 ? false : true} onClick={() => addonPayClick()} className="mt-1 mb-2 btn-apply" variant="outlined" size="m" color="primary">
                        Pay Now
                      </Button></>}

                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Card></>}


    </div>
  );
};

const ManagePack = () => {
  return (
    <div>
      <div className='text-center'>
        <img src={logo} width={180} />
      </div>
      <h1 className='text-center mt-3 mb-4'>Channel Summit Registration Details</h1>
      <Container fluid>
        <Card>
          <CardBody>
            <div>
              <h4 className='text-center'>Main Delegate</h4>
            </div>
            <Col lg='12' className='event-card p-3 mb-4'>
              <Row>
                <Col lg='4' className='pt-3'>
                  <div className='text-center'>
                    <a class="avatar rounded-circle" href="#pablo"><img src="https://d2maj8ifjo1lti.cloudfront.net/document/company/logo/1626101180688_1626101180782_1626101181567.jpg" width={100} /></a>
                    <h4>
                      JamesT
                    </h4>
                  </div>
                </Col>
                <Col lg='4'></Col>
                <Col lg='4'>
                  <h4>Selected Package</h4>
                  <p className='mb-2'>Package Name :</p>
                  <p className='mb-2'>Price :</p>
                </Col>
              </Row>
            </Col>

            <Col lg='12' className='event-card p-3 mb-4' style={{ minHeight: '350px' }}>
              <h4>Select Your Package</h4>

              <Row>
                <Col lg='4' className='pt-3'>
                  <div className='text-center p-2'>
                    <h4>Shared Room</h4>
                    <div>
                      <img src={share} width={180} />
                    </div>
                  </div>
                </Col>
                <Col lg='4'>
                  <div className='p-3' style={{ marginBottom: "20px", minHeight: '250px', border: '1px solid #2c9db7', borderRadius: '5px' }}>

                    <div className='d-flex justify-content-between'>
                      <div>
                        <h3>Extra Buyer Delegate Shared Room</h3>
                        <h4 className='pt-3'>Includes:</h4>
                      </div>
                      <div>
                        <h1 class="h3 text-info text-left"><span className='mr-2 display-1 font-weight-light'> 490</span><i class="fas fa-euro-sign"></i></h1></div>
                    </div>
                    <p class="description text-left"> <i class="far fa-hand-point-right mr-3"></i>2 nights accommodation at Fairmont in shared room  (nights of 19 & 20 May)</p>
                    <p class="description text-left"> <i class="far fa-hand-point-right mr-3"></i>All meals as per event agenda</p>
                    <p class="description text-left"> <i class="far fa-hand-point-right mr-3"></i>Access to workshops and activities</p>
                    <p class="description text-left"> <i class="far fa-hand-point-right mr-3"></i>Airport transfers</p>
                    {/* <p class="description text-left"> <i class="far fa-hand-point-right mr-3"></i>2 nights accommodation</p> */}

                  </div>
                </Col>
                <Col lg='4'></Col>
              </Row>

              <Row>
                <Col lg='4' className='pt-3'>
                  <div className='text-center p-2'>
                    <h4>Single Room</h4>
                    <div>
                      <img src={single} width={180} />
                    </div>
                  </div>
                </Col>
                <Col lg='4'>
                  <div className='p-3' style={{ marginBottom: "20px", minHeight: '250px', border: '1px solid #2c9db7', borderRadius: '5px' }}>

                    <div className='d-flex justify-content-between'>
                      <div>
                        <h3>Extra Buyer Delegate  Single Room</h3>
                        <h4 className='pt-3'>Includes:</h4>
                      </div>
                      <div>
                        <h1 class="h3 text-info text-left"><span className='mr-2 display-1 font-weight-light'> 900 </span><i class="fas fa-euro-sign"></i></h1></div>
                    </div>
                    <p class="description text-left"> <i class="far fa-hand-point-right mr-3"></i>2 nights accommodation at Fairmont in shared room  (nights of 19 & 20 May)</p>
                    <p class="description text-left"> <i class="far fa-hand-point-right mr-3"></i>All meals as per event agenda</p>
                    <p class="description text-left"> <i class="far fa-hand-point-right mr-3"></i>Access to workshops and activities</p>
                    <p class="description text-left"> <i class="far fa-hand-point-right mr-3"></i>Airport transfers</p>
                    {/* <p class="description text-left"> <i class="far fa-hand-point-right mr-3"></i>2 nights accommodation</p> */}

                  </div>
                </Col>
                <Col lg='4'></Col>
              </Row>


              <div className='text-right w-75'>
                <Button color='primary' className='text-uppercase'>Select</Button>
              </div>
            </Col>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export { ManageUser, ManagePack };